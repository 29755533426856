import Karyawan from "./Karyawan";

const KaryawanRoutes = [
  {
    path: "/karyawan",
    component: Karyawan,
  },
];

export default KaryawanRoutes;
