import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  InputAdornment,
  Table,
  TableCell,
  TableRow,
  TextField,
  TableBody,
  TableHead,
  Card,
} from '@material-ui/core';
import {
  getSPK,
  downloadSPK,
  delSPK,
} from 'app/redux/actions/SPKAction';
import { formatTanggal } from 'app/utils/globalFunction';
import { SimpleCard } from 'matx';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

class SPK extends Component {
  constructor() {
    super();
    this.state = {
      openAdd: false,
      type: '',
      modalEdit: false,
      dataEdit: {
        id: '',
        kategori: '',
        type: '',
      },
      search: '',
      excellent: false,
    };
  }

  componentDidMount() {
    const { getSPK } = this.props;
    getSPK();
  }

  handleModalEdit = (data) => {
    this.setState({
      modalEdit: true,
      dataEdit: data,
    });
  };

  handleCloseEdit = () => {
    this.setState({
      modalEdit: false,
    });
  };

  handleDelete = (id) => {
    const { delSPK, getSPK } = this.props;
    Swal.fire({
      title: 'Hapus',
      text: 'Apakah kamu yakin',
      showCancelButton: true,
      confirmButtonText: 'Yakin',
      cancelButtonText: 'Batal',
      icon: 'warning',
    }).then((res) => {
      if (res.isConfirmed) {
        delSPK(id)
          .then((res) => {
            if (res.code === 0) {
              Swal.fire({
                title: 'Berhasil',
                text: 'Data berhasil dihapus',
                timer: 2000,
                icon: 'success',
              });
              getSPK();
            }
          })
          .catch((err) => {
            console.log('err', err);
            Swal.fire({
              title: 'gagal',
              text: 'Data Gagal dihapus',
              timer: 2000,
              icon: 'error',
            });
          });
      }
    });
  };

  printData = (spk_code, send_email) => {
    Swal.fire({
      title: 'Mohon Tunggu !',
      html: 'Mengambil Data..', // add html attribute if you want or remove
      allowOutsideClick: false,
      showConfirmButton: false,
      timer: 7000,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const { downloadSPK } = this.props;
      downloadSPK(spk_code, send_email).then((res) => {
        window.open(res.data.pdf_path);
      });
  };

  renderTable = () => {
    const { search, page, rowsPerPage } = this.state;
    const { dataSPK } = this.props;
    return dataSPK?.length > 0 ? (
      dataSPK
        .filter(
          (item) =>
            item.no_surat?.toLowerCase().includes(search.toLowerCase()) ||
            item.tanggal?.toLowerCase().includes(search.toLowerCase()) ||
            item.pekerjaan?.toLowerCase().includes(search.toLowerCase())
        )
        .map((item, index) => (
          <TableRow hover key={index}>
            <TableCell className='pl-2'>{index + 1}</TableCell>
            <TableCell colSpan={2}>{item.no_surat}</TableCell>
            <TableCell>{formatTanggal(item.tanggal)}</TableCell>
            <TableCell colSpan={2}>{item.pekerjaan}</TableCell>
            <TableCell>{item.member || 0}</TableCell>
            <TableCell align="center" colSpan={3}>
              <ButtonGroup aria-label="group">
                <Button
                  onClick={() => this.handleEdit(item.spk_code)}
                  variant="contained"
                  className="bg-primary text-white elevation-z0"
                  size="small"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => this.printData(item.spk_code, 0)}
                  variant="contained"
                  className="bg-secondary text-white elevation-z0"
                  size="small"
                >
                  Print
                </Button>
                {/* <Button
                  onClick={() => this.printData(item.spk_code, 1)}
                  variant="contained"
                  className="bg-primary text-white elevation-z0"
                  size="small"
                >
                  Send E-mail
                </Button> */}
                <Button
                  onClick={() => this.handleDelete(item.spk_code)}
                  variant="contained"
                  className="bg-error text-white elevation-z0"
                  size="small"
                >
                  Hapus
                </Button>
              </ButtonGroup>
            </TableCell>
          </TableRow>
        ))
    ) : (
      <TableRow hover>
        <TableCell colSpan={8} align="center">
          {' '}
          Data kosong{' '}
        </TableCell>
      </TableRow>
    );
  };

  handleEditKategori = (data, id) => {
    this.setState({
      modalEdit: true,
      dataEdit: {
        id: data.value,
        kategori: data.label,
        type: id,
      },
    });
  };

  handleCloseAdd = () => {
    const { getSPK } = this.props;
    this.setState({
      openAdd: false,
    });
    getSPK();
  };

  handleOpenAdd = (type) => {
    this.setState({
      type: type,
      openAdd: true,
    });
  };

  handleCloseEdit = () => {
    const { getSPK } = this.props;
    this.setState({
      modalEdit: false,
    });
    getSPK();
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleAdd = () => {
    const { history } = this.props;
    history.push('/tambah/spk');
  };

  handleEdit = (param) => {
    const { history } = this.props;
    history.push(`/edit/spk/${param}`);
  };

  render() {
    const { search } = this.state;

    return (
      <div className="m-sm-30">
        <SimpleCard title="SPK" subtitle="Data Surat Perintah Kerja">
          <>
            <Grid
              container
              spacing={2}
              justify="space-between"
              className="my-4"
            >
              <Grid item lg={5} md={5} sm={12} xs={12} className="mb-4">
                <ButtonGroup aria-label="group">
                  <Button
                    onClick={() => this.handleAdd()}
                    variant="contained"
                    color="primary"
                    className="elevation-z0 font-medium"
                  >
                    Tambah SPK
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className="flex items-center">
                  <TextField
                    variant="standard"
                    className="w-full"
                    placeholder="Cari"
                    onChange={this.handleChange}
                    value={search}
                    name="search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>search</Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Card className="p-0 border-radius-0 overflow-auto">
              <Table className="buku-kas-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="pl-2">No.</TableCell>
                    <TableCell colSpan={2}>No Surat</TableCell>
                    <TableCell>Tanggal</TableCell>
                    <TableCell colSpan={2}>Pekerjaan</TableCell>
                    <TableCell>Jml Karyawan</TableCell>
                    <TableCell align="center" colSpan={3}>
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{this.renderTable()}</TableBody>
              </Table>
            </Card>
          </>
        </SimpleCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataSPK: state.spk.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSPK: () => dispatch(getSPK()),
    downloadSPK: (spk_code, send_email) =>
      dispatch(downloadSPK(spk_code, send_email)),
    delSPK: (params) => dispatch(delSPK(params)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SPK);
