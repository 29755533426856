import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";
import { getAllHeader } from "app/redux/actions/SignerActions";
import { getKaryawan } from "app/redux/actions/KaryawanActions";
import { updateSPK, getDetailSPK } from "app/redux/actions/SPKAction";

class EditSPK extends Component {
  constructor() {
    super();
    this.state = {
      spk_code: "",
      tanggal: new Date(),
      pekerjaan: "",
      member: [
        {
          idx: 0,
          karyawan_code: "",
          posisi: "",
          porsi: "",
        },
      ],
      submit: false,
    };
  }

  componentDidMount() {
    const { getAllHeader, getKaryawan, getDetailSPK, match } = this.props;
    const { tanggal } = this.state;
    getAllHeader("list");
    getKaryawan(3);

    getDetailSPK(match.params.id);

    let mulai = JSON.stringify(tanggal);
    mulai = mulai.slice(1, 11);

    this.setState({
      tanggal: mulai,
    });
  }

  componentWillReceiveProps(nextProps) {
    console.log("npppppp", nextProps);
    let dataDetailSPK = nextProps.detailSPK;

    this.setState({
      spk_code: dataDetailSPK?.spk_code,
      kop_code: dataDetailSPK?.kop_code,
      tanggal: dataDetailSPK?.tanggal,
      pekerjaan: dataDetailSPK?.pekerjaan,
      member: dataDetailSPK?.member || [],
    });
  }

  handleChangeItem = (e) => {
    let idx = e.target.id;
    let { member } = this.state;
    let newMemberItem = member;
    newMemberItem[idx][e.target.name] = e.target.value || e.value;
    this.setState({
      member: newMemberItem,
    });
  };

  handleChangeItemKaryawan = (e, idx) => {
    let { member } = this.state;
    let newMemberItem = member;
    newMemberItem[idx]["karyawan_code"] = e.value;
    this.setState({
      member: newMemberItem,
    });
  };

  submitForm = () => this.setState({ submit: true }, () => this.sendSubmit());

  sendSubmit() {
    const {
      spk_code,
      tanggal,
      pekerjaan,
      member,
      kop_code,
    } = this.state;

    const params = {
      spk_code,
      tanggal,
      pekerjaan,
      member,
      kop_code,
    };

    this.submitData(params);
  }

  submitData(params) {
    const { updateSPK, history } = this.props;
    updateSPK(params)
      .then((res) => {
        if (res.code === 0) {
          Swal.fire({
            title: "Berhasil",
            text: "Data berhasil disimpan",
            timer: 2000,
            icon: "success",
          }).then(() => {
            history.push("/spk");
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Gagal",
          text: "Data gagal disimpan",
          timer: 2000,
          icon: "error",
        }).then(() =>
          this.setState({
            submit: false,
          })
        );
      });
  }

  handleChangeDate = (date) => {
    let event = new Date(date);
    let dateFormat = JSON.stringify(event);
    dateFormat = dateFormat.slice(1, 11);
    this.setState({
      tanggal: dateFormat,
    });
  };

  addItem = () => {
    let { member } = this.state;
    let data = [
      {
        idx: Math.random(0, 1000),
        karyawan_code: "",
        posisi: "",
        porsi: "",
      },
    ];

    let all = member.concat(data);

    this.setState({
      member: all,
    });
  };

  deleteItem = (index) => {
    let { member } = this.state;
    let newData = member.filter((item) => item.idx != index);

    this.setState({
      member: newData,
    });
  };

  handleChangeKop = (val) => {
    this.setState({
      kop_code: val.value,
    });
  };

  handleChangeKaryawan = (val) => {
    this.setState({
      nama_karyawan: val.value,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { tanggal, member, pekerjaan, submit, kop_code } = this.state;
    const { dataKaryawan, dataKop } = this.props;
    return (
      <div className="m-sm-30">
        <SimpleCard loading={false} title="Tambah Quotation" minHeight={700}>
          <ValidatorForm ref="form" onSubmit={this.submitForm}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Select
                  value={dataKop.filter((item) => item.value == kop_code)}
                  name="kop_code"
                  options={dataKop}
                  onChange={this.handleChangeKop}
                  className="basic-multi-select mb-4"
                  placeholder="Kop Surat"
                />
              </Grid>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item sm={6} xs={12}>
                  <KeyboardDatePicker
                    disableToolbar
                    className="w-full mt-0"
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Tanggal Quotation"
                    value={tanggal}
                    onChange={this.handleChangeDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextValidator
                    variant="outlined"
                    className="w-full mb-3"
                    label="Pekerjaan"
                    onChange={this.handleChange}
                    type="text"
                    name="pekerjaan"
                    value={pekerjaan}
                    validators={["required"]}
                    errorMessages={["Field is Required"]}
                    size="small"
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <br />
            <br />
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Table className="crud-table">
                  <TableHead className="bg-primary">
                    <TableRow>
                      <TableCell
                        className="py-2 font-poppins font-bold text-white pl-2"
                        colSpan={3}
                      >
                        Karyawan
                      </TableCell>
                      <TableCell
                        className="py-2 font-poppins font-bold text-white pl-2"
                        colSpan={3}
                      >
                        Posisi
                      </TableCell>
                      <TableCell
                        className="py-2 font-poppins font-bold text-white pl-2"
                        colSpan={1}
                      >
                        Porsi
                      </TableCell>
                      <TableCell
                        className="py-2 font-poppins font-bold text-white pl-2"
                        align="center"
                        colSpan={1}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="mt-2">
                    {member.map((item, index) => (
                      <TableRow hover key={index}>
                        <TableCell
                          className="font-poppins p-"
                          align="center"
                          colSpan={3}
                        >
                          <Select
                            // defaultValue={nama_karyawan}
                            value={dataKaryawan.filter(
                              (x) => x.value == item.karyawan_code
                            )}
                            name="karyawan_code"
                            id={"" + index}
                            options={dataKaryawan}
                            onChange={(e) =>
                              this.handleChangeItemKaryawan(e, index)
                            }
                            className="basic-multi-select mb-2"
                            placeholder="Nama Karyawan *"
                          />
                        </TableCell>
                        <TableCell
                          className="font-poppins p-1"
                          align="center"
                          colSpan={3}
                        >
                          <TextValidator
                            variant="outlined"
                            className="w-full mb-3"
                            label="Posisi"
                            onChange={this.handleChangeItem}
                            type="text"
                            name="posisi"
                            value={item.posisi}
                            id={"" + index}
                            validators={["required"]}
                            errorMessages={["Field is Required"]}
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          className="font-poppins p-1"
                          align="center"
                          colSpan={1}
                        >
                          <TextValidator
                            variant="outlined"
                            className="w-full mb-3"
                            label="(%)"
                            onChange={this.handleChangeItem}
                            type="number"
                            name="porsi"
                            value={item.porsi}
                            id={"" + index}
                            validators={["required"]}
                            errorMessages={["Field is Required"]}
                            size="small"
                          />
                        </TableCell>
                        <TableCell
                          className="font-poppins pt-0"
                          align="center"
                          colSpan={1}
                        >
                          <Button
                            variant="contained"
                            className={
                              " text-white elevation-z0 " + member.length == 1
                                ? ""
                                : "bg-error"
                            }
                            onClick={() => this.deleteItem(item.idx)}
                            disabled={member.length == 1 ? true : false}
                          >
                            Hapus
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              className="bg-secondary text-white elevation-z0 mb-4"
              onClick={this.addItem}
            >
              Tambah Item
            </Button>
            <br />
            <br />

            <div className="w-full text-right">
              <ButtonGroup className="mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submit}
                >
                  {submit ? (
                    <CircularProgress size={15} color="#fff" />
                  ) : (
                    "Simpan "
                  )}
                </Button>
              </ButtonGroup>
            </div>
          </ValidatorForm>
        </SimpleCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataKop: state.signer.dataKop,
    dataKaryawan: state.karyawan.data,
    detailSPK: state.spk.detail,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSPK: (params) => dispatch(updateSPK(params)),
    getAllHeader: (type) => dispatch(getAllHeader(type)),
    getKaryawan: (type) => dispatch(getKaryawan(type)),
    getDetailSPK: (code) => dispatch(getDetailSPK(code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditSPK);
