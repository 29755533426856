import Divisi from "./Divisi";

const DivisiRoutes = [
  {
    path: "/divisi",
    component: Divisi,
  },
];

export default DivisiRoutes;
