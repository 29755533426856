import {
  GET_SPK,
  DEL_SPK,
  GET_DETAIL_SPK,
} from '../actions/SPKAction';

const initialState = {
  data: [],
  detail: {},
  detail: null,
};

const SPKReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPK: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case GET_DETAIL_SPK: {
      return {
        ...state,
        detail: action.payload,
      };
    }
    case DEL_SPK: {
      let pelanggan = state.data.filter(
        (item) => item.idx !== action.payload.idx
      );
      return {
        ...state,
        data: pelanggan,
      };
    }
    default: {
      return state;
    }
  }
};

export default SPKReducer;
