import Akun from './akun';

const akunRoutes = [
	{
		path: "/akun",
		component: Akun,
	},
];

export default akunRoutes;
