import Tunjangan from "./Tunjangan";

const TunjanganRoutes = [
  {
    path: "/tunjangan",
    component: Tunjangan,
  },
];

export default TunjanganRoutes;
