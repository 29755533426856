import Mitra from './Mitra';

const MitraRoutes = [
  {
    path: '/mitra',
    component: Mitra,
  },
];

export default MitraRoutes;
