import AddSPK from './AddSPK';
import EditSPK from './EditSPK';
import SPK from './SPK';

const SPKRoutes = [
  {
    path: '/spk',
    component: SPK,
  },
  {
    path: '/tambah/spk',
    component: AddSPK,
  },
  {
    path: '/edit/spk/:id',
    component: EditSPK,
  },
];

export default SPKRoutes;
