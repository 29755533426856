import Office from "./Office";

const OfficeRoutes = [
  {
    path: "/office",
    component: Office,
  },
];

export default OfficeRoutes;
