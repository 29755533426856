import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { SimpleCard } from "matx";
import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { ValidatorForm } from "react-material-ui-form-validator";
import Select from "react-select";
import { getKaryawan } from "app/redux/actions/KaryawanActions";
import { getSigner } from "app/redux/actions/SignerActions";
import {
  editPerjanjianPemagangan,
  getDetailPerjanjianPemagangan,
} from "app/redux/actions/PerjanjianAction";
import { getAllHeader } from "app/redux/actions/SignerActions";

class EditPerjanjianPemagangan extends Component {
  constructor() {
    super();
    this.state = {
      nama_karyawan: "",
      nama_signer: "",
      nama_proyek: "",
      judulContent: "",
      index: 0,
      submit: false,
    };
  }

  componentDidMount() {
    const {
      getDetailPerjanjianPemagangan,
      getSigner,
      getKaryawan,
      dataSigner,
      dataKaryawan,
      getAllHeader,
    } = this.props;
    const { match } = this.props;

    getSigner();
    getKaryawan(2);
    getDetailPerjanjianPemagangan(match.params.id);
    getAllHeader("list");

    this.setState({
      optionDataSigner: dataSigner,
      optionDataKaryawan: dataKaryawan,
    });
  }

  submitForm = () => this.setState({ submit: true }, () => this.sendSubmit());

  sendSubmit() {
    const { nama_karyawan, nama_signer, kop_code } = this.state;
    const { match } = this.props;

    const params = {
      karyawan_code: nama_karyawan,
      signer_code: nama_signer,
      unique_code: match.params.id,
      kop_code: kop_code,
    };

    this.submitData(params);
  }

  submitData(params) {
    const { editPerjanjianPemagangan, history } = this.props;
    editPerjanjianPemagangan(params)
      .then((res) => {
        if (res.code === 0) {
          Swal.fire({
            title: "Berhasil",
            text: "Data berhasil di perbaharui",
            timer: 2000,
            icon: "success",
          }).then(() => {
            history.push("/perjanjian_pemagangan");
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Gagal",
          text: "Data gagal di perbaharui",
          timer: 2000,
          icon: "error",
        }).then(() =>
          this.setState({
            submit: false,
          })
        );
      });
  }

  handleChangeKaryawan = (val) =>
    this.setState({ ...this.state, nama_signer: val.value });

  handleChangePelanggan = (val) =>
    this.setState({ ...this.state, nama_karyawan: val.value });

  handleLanguage = (val) => {
    this.setState({
      bahasa: val.value,
    });
  };

  componentWillReceiveProps(nextProps) {
    const { detailPerjanjian } = nextProps;
    this.setState({
      nama_karyawan: detailPerjanjian?.karyawan_code,
      nama_signer: detailPerjanjian?.signer_code,
      kop_code: detailPerjanjian?.kop_code,
    });
  }

  handleChangeKop = (val) => {
    this.setState({
      kop_code: val.value,
    });
  };

  render() {
    const {
      nama_karyawan,
      nama_signer,
      submit,
      kop_code,
    } = this.state;
    const { dataKaryawan, dataSigner, dataKop } = this.props;

    return (
      <div className="m-sm-30">
        <SimpleCard loading={false} title="Edit Perjanjian Kerja">
          <ValidatorForm ref="form" onSubmit={this.submitForm}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Select
                  value={dataKop?.filter((item) => item.value == kop_code)}
                  name="kop_code"
                  options={dataKop}
                  onChange={this.handleChangeKop}
                  className="basic-multi-select mb-4"
                  placeholder="Kop Surat"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Select
                  value={dataKaryawan?.filter(
                    (item) => item.value === nama_karyawan
                  )}
                  name="nama_karyawan"
                  options={dataKaryawan}
                  onChange={this.handleChangePelanggan}
                  className="basic-multi-select mb-4"
                  placeholder="Nama Karyawan *"
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Select
                  value={dataSigner?.filter(
                    (item) => item.value === nama_signer
                  )}
                  name="nama_signer"
                  options={dataSigner}
                  onChange={this.handleChangeKaryawan}
                  className="basic-multi-select mb-4"
                  placeholder="Nama Signer *"
                />
              </Grid>
            </Grid>
            <br />
            <br />
            <br />

            <div className="w-full text-right">
              <ButtonGroup className="mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submit}
                >
                  {submit ? (
                    <CircularProgress size={15} color="#fff" />
                  ) : (
                    "Simpan "
                  )}
                </Button>
              </ButtonGroup>
            </div>
          </ValidatorForm>
        </SimpleCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataKaryawan: state.karyawan.data,
    dataSigner: state.signer.data,
    detailPerjanjian: state.perjanjian.detailPemagangan,
    dataKop: state.signer.dataKop,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getKaryawan: (type) => dispatch(getKaryawan(type)),
    getSigner: () => dispatch(getSigner()),
    editPerjanjianPemagangan: (params) => dispatch(editPerjanjianPemagangan(params)),
    getDetailPerjanjianPemagangan: (params) =>
      dispatch(getDetailPerjanjianPemagangan(params)),
    getAllHeader: (type) => dispatch(getAllHeader(type)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditPerjanjianPemagangan);
