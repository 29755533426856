import PerjanjianPemagangan from "./PerjanjianPemagangan";
import EditPerjanjianPemagangan from "./EditPerjanjianPemagangan";
import TambahPerjanjianPemagangan from "./TambahPerjanjianPemagangan";

const PerjanjianPemaganganRoutes = [
  {
    path: "/perjanjian_pemagangan/:id/edit",
    exact: true,
    component: EditPerjanjianPemagangan,
  },
  {
    path: "/perjanjian_pemagangan/tambah",
    exact: true,
    component: TambahPerjanjianPemagangan,
  },
  {
    path: "/perjanjian_pemagangan",
    exact: false,
    component: PerjanjianPemagangan,
  },
];

export default PerjanjianPemaganganRoutes;
