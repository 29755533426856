import Signer from "./Signer";

const SignerRoutes = [
  {
    path: "/signer",
    component: Signer,
  },
];

export default SignerRoutes;
